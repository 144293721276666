#new-resources-container {
	display: flex;
	width: 100vw;
	max-width: 1336px;
	margin-left: auto;
	margin-right: auto;
	flex-wrap: wrap;
	box-sizing: border-box;

	* {
		box-sizing: border-box !important;
	}

	.terms-side {
		flex: 0 0 30%;
		position: relative;
		padding-left: 120px;
		padding-top: 80px;
		padding-bottom: 60px;

		@media screen and (max-width: 1280px) {
			padding-left: 60px;
		}

		@media screen and (max-width: 960px) {
			flex: 0 0 100%;
			padding-top: 60px;
		}

		@media screen and (max-width: 600px) {
			padding-left: 20px !important;
			padding-top: 40px !important;
			padding-bottom: 30px !important;
		}

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0px;
			right: 0px;
			width: 100vw;
			height: 100%;
			background: #EAEAEA;
			z-index: 1;
		}

		h4 {
			display: block;
			position: relative;
			z-index: 2;
			margin: 0px;
			font-size: 18px;
			line-height: 24px;
			font-weight: bold;
			padding-bottom: 15px;
			font-family: 'factoria';
		}

		span {
			display: block;
			position: relative;
			z-index: 2;
			margin: 0px;
			font-size: 18px;
			line-height: 24px;
			padding-bottom: 10px;
			font-family: 'factoria';
		}

		@media screen and (max-width: 960px) {
			h4,
			span {
				display: none;
			}
		}

		ul {
			position: relative;
			z-index: 2;
			display: flex;
			flex-direction: column;
			margin: 0px;
			padding: 0px;
			list-style-type: none;
			margin-bottom: 10px;

			@media screen and (max-width: 960px) {
				display: none;
			}

			li {
				font-size: 18px;
				line-height: 26px;
				padding: 0px;
				margin: 0px;
				padding-bottom: 5px;
				margin-right: 16px;
				color: #CA3339;
				font-family: 'factoria';
				cursor: pointer;
			}
		}

		select {
			appearance: none;
			position: relative;
			z-index: 3;
			display: block;
			opacity: 1;
			width: 80%;
			background: white;
			color: #30343a;
			padding: 10px;
			font-size: 18px;
			border: 1px solid black;
			font-family: 'factoria';
			font-weight: bold;

			@media screen and (min-width: 961px) {
				display: none;
			}
		}

		option {
			appearance: none;
			background: white;
			color: #30343a;
			font-size: 16px;
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}

	.resources-side {
		flex: 0 0 70%;
		padding-left: 120px;
		padding-right: 120px;
		padding-top: 80px;
		padding-bottom: 60px;

		@media screen and (max-width: 1280px) {
			padding-left: 60px;
			padding-right: 60px;
		}

		@media screen and (max-width: 960px) {
			flex: 0 0 100%;
			padding-top: 60px;
		}

		@media screen and (max-width: 600px) {
			padding-left: 20px !important;
			padding-right: 20px !important;
		}

		h3 {
			display: block;
			margin: 0px;
			font-size: 28px;
			line-height: 34px;
			font-weight: bold;
			padding-bottom: 40px;
			font-family: 'factoria';
		}

		a.back {
			display: block;
			margin: 0px;
			font-size: 18px;
			line-height: 24px;
			color: #CA3339;
			padding-bottom: 60px;
			font-family: 'factoria';
		}

		.resource-content {
			padding-bottom: 200px;
			max-width: 605px;

			@media screen and (max-width: 960px) {
				padding-bottom: 50px;
			}

			p {
				font-size: 20px;
				line-height: 32px;
				font-family: "museo-sans-condensed";
			}

			img {
				display: block;
				height: auto;
				max-width: 90%;
				margin-top: 30px;
				margin-bottom: 30px;
				border: 1px solid #707070;
			}
		}
	}
}

.load-more-resources-button {
	margin-top: 45px;
}

.resource:nth-of-type(n+7) {
	display: none;
}

.resource {
	display: flex;
	padding-bottom: 40px;

	.text-side {
		flex: 1;

		h4 {
			display: block;
			margin: 0px;
			font-size: 18px;
			line-height: 24px;
			font-weight: bold;
			color: #CA3339;
			padding-bottom: 10px;
			font-family: 'factoria';
			padding-right: 50px;
		}

		.description p {
			font-size: 20px;
			line-height: 32px;
			font-family: "museo-sans-condensed";
			padding-right: 45px;
			color: #30343a;
		}

		ul {
			display: flex;
			flex-wrap: wrap;
			margin: 0px;
			padding: 0px;
			list-style-type: none;

			li {
				font-size: 18px;
				line-height: 26px;
				padding: 0px;
				margin: 0px;
				margin-right: 16px;
				color: #CA3339;
				font-family: 'factoria';
				white-space: nowrap;
				transition: all .2s ease-in-out;

				&:hover {
					cursor: pointer;
					text-decoration: underline;
				}
			}
		}
	}

	.image-side {
		flex: 0 0 72px;

		@media screen and (max-width: 600px) {
			flex: 0 0 40px;
		}

		img {
			display: block;
			width: 72px;
			max-width: 100%;
			height: auto;
		}
	}
}

@media screen and (max-width: 800px) {
	.home.page-template-default .recent-news .container {
		max-width: 98% !important;
	}
}

.recent-news {

	.container {
		width: 1440px;
		max-width: 90%;
	}


	.resource-home {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.resource {
			flex: 0 0 45%;

			@media screen and (max-width: 1000px) {
				flex: 0 0 100%;
			}
		}
	}
}

a.no-link {
	cursor: default !important;
}
