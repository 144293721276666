.page-template-training-videos {


	h2 {
		text-transform: uppercase !important;
		display: inline-block !important;
		text-align: center !important;
		position: relative !important;
		margin-bottom: 40px !important;
		font-family: 'factoria' !important;
		font-size: 28px !important;
		font-weight: 800 !important;
		letter-spacing: 3px !important;
		color: #30343a !important;
		margin-top: 75px !important;
		margin-bottom: 0px !important;
		line-height: 1.4em !important;
	}


	#video-loop {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		width: 100%;
		max-width: 1250px;
		margin-left: auto;
		margin-right: auto;
		padding: 0px;
		margin-top: 40px;
		margin-bottom: 60px;

		& * {
			box-sizing: border-box;
		}

		.video {

			@media screen and (min-width: 961px) {
				flex: 0 0 32%;
				margin-bottom: 60px;

				&:not(:nth-child(3n+1)) {
					margin-left: calc((100% - (32% * 3)) / 2);
				}
			}

			@media screen and (min-width: 601px) and (max-width: 960px) {
				flex: 0 0 48%;
				margin-bottom: 60px;

				&:not(:nth-child(2n+1)) {
					margin-left: calc((100% - (48% * 2)) / 1);
				}
			}

			@media screen and (max-width: 600px) {
				flex: 0 0 100%;
				margin-bottom: 60px;
			}


			.video-image {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 225px;
				background-color: #f1f1f1;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;

				.play-button {
					display: flex;
					width: 100%;
					height: 100%;
					justify-content: center;
					align-items: center;
					cursor: pointer;

					&:hover {
						opacity: .9;
					}

					& > div {
						display: flex;
						width: 50px;
						height: 50px;
						justify-content: center;
						align-items: center;
						background: #ca3339;
						border-radius: 50%;

						svg {
							fill: white;
							position: relative;
							left: 2px;
							pointer-events: none;
						}
					}
				}
			}

			h4 {
				color: #ca3339;
				font-weight: bold;
				padding-top: 10px;
				text-transform: uppercase;
			}

			p {
				color: #30343a;
				padding-right: 15px;
			}
		}
	}




	.modal.video-modal {
		background: rgba(0,0,0,.6);

		.modal-dialog {
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			width: 85%;
			max-width: 500px;
			margin: 0px;
		}

		.modal-content {
			background: #fff;
			border-radius: 0px;
			box-shadow: none;
			border: none;
		}

		.modal-body {
			padding: 0px;
		}

		button.close {
			top: -45px;
			right: 5px;
			color: #fff;
		}
	}
}
