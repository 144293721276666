.page-template-partners {

	#partners-loop {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		width: 100%;
		max-width: 900px;
		margin-left: auto;
		margin-right: auto;
		padding: 0px;
		margin-top: 40px;
		margin-bottom: 60px;

		& * {
			box-sizing: border-box;
		}

		.partner {
			background: #fff;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 300px;

			@media screen and (min-width: 961px) {
				flex: 0 0 32%;
				margin-bottom: 20px;

				&:not(:nth-child(3n+1)) {
					margin-left: calc((100% - (32% * 3)) / 2);
				}
			}

			@media screen and (min-width: 601px) and (max-width: 960px) {
				flex: 0 0 48%;
				margin-bottom: 20px;

				&:not(:nth-child(2n+1)) {
					margin-left: calc((100% - (48% * 2)) / 1);
				}
			}

			@media screen and (max-width: 600px) {
				flex: 0 0 100%;
				margin-bottom: 20px;
			}

			// overlay
			&::before {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				-webkit-transition-delay: 0.05s;
				transition-delay: 0.05s;
				-webkit-transition-duration: 0.3s;
				transition-duration: 0.3s;
				background: rgba(48, 52, 58, .95);
				content: '';
				opacity: 0;
			}

			&:hover::before {
				opacity: 1;
			}

			.hidden-content {
				display: flex;
				position: absolute;
				flex-direction: column;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
				justify-content: center;
				align-content: center;
				text-align: center;
				z-index: 3;
				opacity: 0;
				pointer-events: none;
				transition: all .15s linear .15s;
				transform: translateY(-20px);

				a {
					display: block;
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
				}

				h4 {
					color: #fff;
					font-family: 'factoria';
					font-weight: 800;
					font-size: 24px;
					letter-spacing: 3px;
					text-transform: uppercase;
					padding-right: 6px;
					padding-left: 6px;
				}

				h5 {
					font-size: 22px;
					color: #ca3339;
					font-size: 20px;
					margin-top: 0px;
					padding-right: 6px;
					padding-left: 6px;
				}

				p {
					font-size: 15px;
					padding-right: 6px;
					padding-left: 6px;
				}
			}

			&:hover .hidden-content {
				opacity: 1;
				pointer-events: auto;
				transform: translateY(0px);
			}

			img {
				display: block;
				height: auto;
				max-width: 85%;
				max-height: 85%;
			}
		}
	}
}
