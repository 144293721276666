// .nav-toggle {
// 	display: none !important;
// }

@media only screen and (max-width: 640px) {
	.container-fluid.hero.announcement {
		padding: calc(25vh) 20px 60px;
	}
}

#announcement-bar {
	display: none;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 9999999999;
	background: #30343a;
	color: #fff;
	width: 100%;
	padding: 15px 20px;

	@media screen and (max-width: 600px) {
		top: auto;
		bottom: 0px;
	}

	.columns {
		width: 100%;
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;

		a {
			color: #fff;
			letter-spacing: 3px;
			text-transform: uppercase;
			background: rgba(202, 51, 57, 1);
			border: 0;
			outline: 0;
			font-family: "factoria";
			font-size: 14px;
			font-weight: 800;
			background-image: none;
			border-radius: 0;
			padding: 10px 15px;
			margin-left: 15px;
			white-space: nowrap;

			@media screen and (max-width: 1200px) {
				font-size: 12px;
				padding: 7px 10px;
			}
		}
	}
}

#menu-main-menu > li.current-menu-item a {
	background: transparent;
	border-bottom: 2px solid #ca3339;
}

#menu-main-menu > li > ul > li.current-menu-item a {
	color: #ca3339;
	border-bottom: none;
}

header #desktop-nav .nav-menu div.desktop-view {
	display: none;
}

header #desktop-nav .nav-menu {
	display: block !important;
	box-shadow: none !important;
}

header #desktop-nav.scroll .nav-menu {
	box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, .66);
}

.home .nav-desktop.scroll, .nav-desktop.scroll, nav.navbar.navbar-default.background, nav.navbar.navbar-default.scroll,
.home .nav-desktop.scroll, .nav-desktop.scroll, nav.navbar.navbar-default.background, nav.navbar.navbar-default.scroll {
	min-height: none !important;
}

.home .nav-desktop,
.nav-desktop,
nav.navbar.navbar-default.background,
nav.navbar.navbar-default {
	min-height: 84px;
	transition: all .3s linear;
}

.home .nav-desktop.scroll, .nav-desktop.scroll, nav.navbar.navbar-default.background, nav.navbar.navbar-default.scroll {
	background: #282b30;
	min-height: 84px;
}

.home .nav-desktop .navbar-brand {
	opacity: 1 !important;
}

.hero .logo {
	opacity: 0;
	pointer-events: none;
}

.navbar-toggle {
	display: block !important;
}
@media only screen and (min-width: 1161px) {
	.navbar-toggle {
		display: none !important;
	}
}

@media only screen and (max-width: 1160px) {
	// .desktop-nav {
	// 	display: none;
	// }
	#menu-main-menu {
		display: none;
	}
}

@media only screen and (min-width: 1161px) {
	#menu-main-menu .sub-menu {
		background: #30343a;
		height: auto;

		li {
			padding: 0px 15px;

			a {
				display: block;
				padding: 20px 0px;
				border-bottom: none;

				&:hover {
					color: #ca3339;
					border-bottom: none;
				}

				&::after {
					display: none;
				}
			}
		}
	}
}

.home .nav-desktop.scroll, .nav-desktop.scroll, nav.navbar.navbar-default.background, nav.navbar.navbar-default.scroll {
	min-height: 84px !important;
	height: 84px !important;
}

@media screen and (max-width: 1160px) {
	ul.navbar-nav {
		background: #282b30;
	}
}
