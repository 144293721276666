@media screen and (max-width: 1160px) {
	body > header {
		display: none;
	}

	body {
		width: 100vw;
		overflow-x: hidden;
	}
}


/********************************************************/
/* MOBILE MENU */
/* HAMBURGER */
/********************************************************/
$mobile_menu_breakpoint: 1160px;
$hamburger_width: 35px;
$hamburger_line_spacing: 7px;
$hamburger_line_thickness: 3px;
$hamburger_active_offset: 10px;
$hamburger_color: #fff;

#mobile-menu-hamburger {
	display: none;

	@media screen and (max-width: $mobile_menu_breakpoint) {
		display: block;
		background: #282b30;
		width: 100%;
		position: fixed;
		z-index: 999999999;
	}

	.columns {
		display: flex;
		flex-wrap: wrap;
		padding-top: 15px;
		padding-bottom: 15px;
		padding-left: 32px;
		padding-right: 32px;

		@media screen and (max-width: 600px) {
			padding-left: 16px;
			padding-right: 16px;
		}

		.logo-side {
			display: flex;
			flex: 0 0 70%;
			align-items: center;

			img {
				display: block;
				width: auto;
				max-width: 120px;
				margin: 0px;
			}
		}

		// HAMBURGER SIDE
		.hamburger-side {
			display: flex;
			flex: 0 0 30%;
			position: relative;
			z-index: 9999999999;
			justify-content: flex-end;
			align-items: center;

			#hamburger {
				display: block;
				position: relative;
				width: $hamburger_width;
				cursor: pointer;
				transform: scale(.8);
				transform-origin: center;

				.line {
					display: block;
					width: 100%;
					height: $hamburger_line_thickness;
					background-color: $hamburger_color;
					margin: $hamburger_line_spacing auto;
					transition: all 0.3s ease-in-out;
				}

				&.active .line:nth-child(1) {
				  transform: translateY($hamburger_active_offset) rotate(45deg);
				}

				&.active .line:nth-child(2) {
					opacity: 0;
				}

				&.active .line:nth-child(3) {
					transform: translateY(-$hamburger_active_offset) rotate(-45deg);
				}
			}
		}
	}
}



/********************************************************/
/* MOBILE MENU */
/********************************************************/
$mobile_menu_timing: .25s;

@media screen and (max-width: $mobile_menu_breakpoint) {
	body.mobile-menu-open {
		height: 100%;
		overflow: hidden;
	}
}

#mobile-menu {
	display: none;
	pointer-events: none;
	opacity: 0;

	@media screen and (max-width: $mobile_menu_breakpoint) {
		display: block;
		position: fixed;
		background: #f1f1f1;
		width: 100%;
		height: 100%;
		transition: all $mobile_menu_timing ease-in-out $mobile_menu_timing;
		overflow-y: scroll;

		&.active {
			opacity: 1;
			pointer-events: auto;
			z-index: 8;
			transition: all $mobile_menu_timing ease-in-out;
		}
	}

	.column > ul {
		display: block;
		transform: translateX(-25%);
		opacity: .5;
		transition: all $mobile_menu_timing ease-in-out;
		padding-top: 110px;
		padding-left: 16px;
		padding-bottom: 150px;
		list-style-type: none;

		& > li {
			padding: 7px 0px;

			& > a {
				display: block;
				position: relative;
				font-family: 'factoria';
				text-transform: uppercase;
				font-size: 22px;
				line-height: 31px;
				font-weight: 300;
				color: #30343a;
				text-decoration: none;
				padding-right: 30px;

				&.current_page {
					color: #ca3339;
				}

				& > svg {
					display: block;
					position: absolute;
					top: 2px;
					right: 20px;
					width: 15px;
					margin-left: 10px;
					pointer-events: none;
				}
			}

			&.current-menu-item > a {
				color: #ca3339;
			}

			&.menu-button > a {
				display: inline-block;
				color: #fff;
				letter-spacing: 3px;
				text-transform: uppercase;
				background: rgba(202, 51, 57, 1);
				border: 0;
				outline: 0;
				font-family: "factoria";
				font-weight: 800;
				background-image: none;
				border-radius: 0;
				font-size: 16px;
				padding: 20px 40px;
				text-align: center;
				margin-right: 0px;
				margin-top: 20px;
				max-width: 100%;
			}
		}
	}

	&.active .column > ul {
		transform: translateX(0%);
		opacity: 1;
		transition: all $mobile_menu_timing ease-in-out $mobile_menu_timing;
	}


	// 2ND LEVEL NAV
	.column > ul > li > ul {
		display: none;
		padding-left: 0px;
		padding-top: 10px;
		list-style-type: none;

		& > li {

			& > a {
				display: block;
				font-family: 'factoria';
				font-size: 18px;
				line-height: 28px;
				font-weight: 300;
				color: #30343a;
			}

			&.current-menu-item > a {
				color: #ca3339;
			}
		}
	}
}
