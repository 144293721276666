@import "partials/_main-menu-rework";
@import "partials/_training-videos";
@import "partials/_partners";
@import "partials/_mobile-menu";
@import "partials/book-online";
@import "partials/_resources";

// .padded-top,
#site-container {
	width: 100%;
	overflow-x: hidden;

	//
	// .container-fluid.hero.interior {
	// 	padding: calc(30vh) 0 150px;
	// }
}

.recent.news {
	display: flex;
	flex-wrap: wrap;

	& > div {
		flex: 0 0 33.333%;
	}
}

.page-template-default {

	.testimonial {
		margin-bottom: 100px;
	}

	.testimonial.padded-top {
		margin-top: 80px;
	}
}

.news-blog.mb {
	margin-bottom: 60px;
}

.new-patients.wider {
	width: 100%;
	max-width: 1300px;
}

.intake-disclaimer {
	display: block;
	width: 100%;
	max-width: 600px;
	text-align: center;
	margin: 0 auto;
	margin-bottom: 30px;
}

.intake-form-columns {
	text-align: center;

	.location-icon {
		display: flex;
		width: 50px;
		height: 50px;
		justify-content: center;
		align-items: center;
		background: #ca3339;
		border-radius: 50%;
		margin: 0 auto;
		margin-top: 40px;

		@media screen and (max-width: 600px) {
			margin-top: 80px;
		}

		img {
			display: block;
			width: 50%;
			height: auto;
		}
	}

	p.address {
		font-size: 20px;
		margin-top: 10px;
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		list-style-type: none;
		justify-content: center;
		padding: 0px;
		margin: 0px;

		li {
			padding: 0px 10px;
		}
		li:nth-of-type(2) {
			border-left: 1px solid #000;
		}

		a {
			// font-size: 12px !important;
			// line-height: 20px !important;
			font-weight: 400 !important;
			color: #30343a !important;

			&:hover {
				color: #ca3339 !important;
			}
		}
	}
}

a.intake-form {
	color: #30343a !important;

	&:hover {
		color: #ca3339 !important;
	}
}


@media screen and (max-width: 991px) {
	.container-fluid.callouts>.row>div:first-of-type,
	.container-fluid.callouts>.row>div:last-of-type,
	.container-fluid.callouts>.row>div {
		padding: 20px 20px 0px 20px;
	}
}

@media screen and (min-width: 992px) {

	.container-fluid.callouts>.row>div:nth-of-type(4) {
		padding: 20px 10px 10px 20px;
	}

	.container-fluid.callouts>.row>div:nth-of-type(3),
	.container-fluid.callouts>.row>div:nth-of-type(6) {
		padding: 20px 20px 10px 10px;
	}

	.container-fluid.callouts>.row>div:nth-of-type(4),
	.container-fluid.callouts>.row>div:nth-of-type(5),
	.container-fluid.callouts>.row>div:nth-of-type(6) {
		padding-top: 10px;
	}
}


#menu-main-menu > li.menu-item-has-children > a {
	cursor: default;
}

#slideshow-wrap {
	position: relative;

	ul.slick-dots {
		display: flex !important;
		position: absolute;
		bottom: 16px;
		left: 50%;
		transform: translateX(-50%);
		list-style-type: none;
		padding: 0px;
		margin: 0px;

		li {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			border: 1px solid #ca3339;
			margin: 0px 3px;
			transition: all .2s ease-in-out;

			&.slick-active {
				background: #ca3339;
			}

			button {
				opacity: 0;
			}
		}
	}
}

.contact {

	.row.maxed {
		max-width: 700px;
		margin: 0 auto;
	}

	.map {
		margin-top: 100px;

		&:nth-of-type(1) {
			margin-top: 50px;
		}

		&:last-of-type {
			margin-bottom: 75px;
		}

		a.black-text {
			font-family: 'Factoria';
			font-weight: 400;
			font-size: 18px;
			line-height: 26px;
			color: #30343a;
			transition: all .2s ease-in-out;

			&:hover {
				color: #ca3339;
			}
		}
	}
}

.footer .location a {
	color: #fff;
	transition: all .2s ease-in-out;

	&:hover {
		color: #ca3339;
	}

	@media screen and (max-width: 600px) {
		display: inline-block;
		padding-bottom: 12px;
	}
}

.footer .icon-button i {
	z-index: 7;
}

.carousel-control .glyphicon-chevron-right, .carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left, .carousel-control .icon-prev {
	width: 40px;
	height: 40px;
}

.modal-footer .next-bio,
.modal-footer .previous-bio {
	display: none;
}

@media screen and (max-width: 600px) {
	.container-fluid.gallery .carousel-inner .item > div {
		width: 100% !important;
		left: -16px;
		position: relative;
	}
	.container-fluid.gallery .carousel-inner .item > div.col-two,
	.container-fluid.gallery .carousel-inner .item > div.col-three,
	.container-fluid.gallery .carousel-inner .item > div.col-four,
	.container-fluid.gallery .carousel-inner .item > div.col-five {
		display: none;
	}
}

@media screen and (max-width: 600px) {
	.team-member::before,
	.team-member a {
		opacity: 0 !important;
	}
}

@media screen and (max-width: 800px) {
	.container-fluid.gallery {
		display: none;
	}
}
@media screen and (min-width: 801px) {
	#mobile-image-gallery-container {
		display: none;
	}
}

#mobile-image-gallery-container {
	background: #dde1e6;
	padding-top: 80px;
	padding-bottom: 80px;
	text-align: center;
	box-shadow: inset 0px 10px 54px 6px rgba(194, 201, 211, 66%);

	.relative {
		position: relative;
	}

	h2 {
		font-weight: 800;
		margin-bottom: 25px;
	}

	#mobile-image-gallery {
		display: block;
		width: calc(95vw - 75px);
		max-width: 800px;
		margin: 0 auto;

		& > div {
			padding: 0px !important;
		}
	}

	.left {
		left: 10px;

		span {
			width: 30px;
			height: 30px;
		}
	}

	.right {
		right: 10px;

		span {
			width: 30px;
			height: 30px;
		}
	}
}
