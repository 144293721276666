.page-template-book-online {

	.two-col {
		display: flex;
		flex-wrap: wrap;

		&,
		* {
			box-sizing: border-box;
		}

		.content-side {
			flex: 0 0 60%;
			padding-right: 90px;

			@media screen and (max-width: 800px) {
				flex: 0 0 100%;
				padding-right: 0px;
			}

			.btn {
				margin-top: 50px;
			}
		}

		.address-side {
			flex: 0 0 40%;
			max-width: 100% !important;

			@media screen and (max-width: 800px) {
				flex: 0 0 100%;
				margin-top: 100px;
			}

			.map {
				margin-top: 40px;
			}

			a {
				color: #30343a;

				&:hover {
					color: #ca3339;
				}
			}
		}
	}
}
